var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',[_c('el-row',{staticClass:"filter-wrap",attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":4}},[_c('div',{staticClass:"flex-start"},[_c('el-input',{attrs:{"placeholder":"请输入内容","clearable":""},model:{value:(_vm.listQuery.SearchStr),callback:function ($$v) {_vm.$set(_vm.listQuery, "SearchStr", $$v)},expression:"listQuery.SearchStr"}})],1)]),_c('el-col',{attrs:{"span":3}},[_c('el-button',{attrs:{"size":"small","type":"primary","icon":"el-icon-search"},on:{"click":_vm.handleFilter}},[_vm._v(" 搜索 ")])],1),_c('el-col',{staticClass:"text-right",attrs:{"span":17}},[_c('el-button',{attrs:{"size":"small","type":"primary","icon":"el-icon-plus"},on:{"click":function($event){return _vm.$router.push({path: '/lawCaseSave'})}}},[_vm._v(" 新增 ")])],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"border":"","fit":"","highlight-current-row":"","data":_vm.tableData,"stripe":""}},[_c('el-table-column',{attrs:{"label":"标题","show-overflow-tooltip":true,"prop":"title"}}),_c('el-table-column',{attrs:{"label":"内容","show-overflow-tooltip":true,"prop":"content"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{domProps:{"innerHTML":_vm._s(row.content)}})]}}])}),_c('el-table-column',{attrs:{"label":"发生时间","prop":"occurrenceTime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.occurrenceTime ? _vm.formatDateStr(row.occurrenceTime) : '')+" ")]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":true,"label":"涉及的法律条文","prop":"lawDetailsName"}}),_c('el-table-column',{attrs:{"label":"添加人","prop":"createName"}}),_c('el-table-column',{attrs:{"label":"添加时间","prop":"createTime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.createTime ? _vm.formatDateStr(row.createTime) : '')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-link',{staticClass:"mr10",attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({path: '/lawCaseSave', query: { id: row.id}})}}},[_c('el-tooltip',{attrs:{"content":"编辑","placement":"top","effect":"light"}},[_c('i',{staticClass:"el-icon-edit"})])],1),_c('el-link',{attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.delLaw(row.id)}}},[_c('el-tooltip',{attrs:{"content":"删除","placement":"top","effect":"light"}},[_c('i',{staticClass:"el-icon-delete"})])],1)]}}])})],1),_c('pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.total>0),expression:"total>0"}],attrs:{"total":_vm.total,"page":_vm.listQuery.page,"limit":_vm.listQuery.pageSize},on:{"update:page":function($event){return _vm.$set(_vm.listQuery, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listQuery, "pageSize", $event)},"pagination":_vm.getList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }