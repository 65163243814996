<template>
  <el-card>
    <el-row
      class="filter-wrap"
      :gutter="10"
    >
      <el-col :span="4">
        <div class="flex-start">
          <el-input
            v-model="listQuery.SearchStr"
            placeholder="请输入内容"
            clearable
          />
        </div>
      </el-col>
      <el-col :span="3">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="handleFilter"
        >
          搜索
        </el-button>
      </el-col>
      <el-col
        :span="17"
        class="text-right"
      >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="$router.push({path: '/lawCaseSave'})"
        >
          新增
        </el-button>
      </el-col>
    </el-row>
    <el-table
      v-loading="loading"
      border
      fit
      highlight-current-row
      :data="tableData"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="标题"
        :show-overflow-tooltip="true"
        prop="title"
      />
      <el-table-column
        label="内容"
        :show-overflow-tooltip="true"
        prop="content"
      >
        <template slot-scope="{row}">
          <div v-html="row.content" />
        </template>
      </el-table-column>
      <el-table-column
        label="发生时间"
        prop="occurrenceTime"
      >
        <template slot-scope="{row}">
          {{ row.occurrenceTime ? formatDateStr(row.occurrenceTime) : '' }}
        </template>
      </el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        label="涉及的法律条文"
        prop="lawDetailsName"
      />
      <el-table-column
        label="添加人"
        prop="createName"
      />
      <el-table-column
        label="添加时间"
        prop="createTime"
      >
        <template slot-scope="{row}">
          {{ row.createTime ? formatDateStr(row.createTime) : '' }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="120px"
      >
        <template slot-scope="{row}">
          <el-link
            class="mr10"
            type="primary"
            @click="$router.push({path: '/lawCaseSave', query: { id: row.id}})"
          >
            <el-tooltip
              content="编辑"
              placement="top"
              effect="light"
            >
              <i class="el-icon-edit" />
            </el-tooltip>
          </el-link>
          <el-link
            :underline="false"
            type="primary"
            @click="delLaw(row.id)"
          >
            <el-tooltip
              content="删除"
              placement="top"
              effect="light"
            >
              <i class="el-icon-delete" />
            </el-tooltip>
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.pageSize"
      @pagination="getList"
    />
  </el-card>
</template>

<script>
import pagination from '@core/components/pagination/Pagination.vue'
import { error, success } from '@core/utils/utils'
import { LawsCasePage, DeleteLawCaseByIds } from '@/api/knowledgeBase/laws.js'

export default {
  components: {
    pagination,
  },

  data() {
    return {
      loading: false,
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        searchStr: '',
        typeCode: '',
      },
      tableData: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    delLaw(id) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        DeleteLawCaseByIds({ ids: [id] }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.handleFilter()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    getList() {
      this.loading = true
      LawsCasePage(this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>
